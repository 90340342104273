import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Header from "./Header";
import Gallery from "react-photo-gallery";
import Lightbox from "react-images";
import photos from "../data/photos.json";
import Fade from "@material-ui/core/Fade";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  content: {
    padding: "15px"
  }
});

class PhotoGallery extends Component {
  state = { currentImage: 0 };

  openLightbox = (e, obj) => {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  };

  render() {
    return (
      <Fade in={true} timeout={1000}>
        <div>
          <Header active="photos" />
          <div>
            <Gallery photos={photos} onClick={this.openLightbox} />
            <Lightbox
              images={photos}
              onClose={this.closeLightbox}
              onClickPrev={this.gotoPrevious}
              onClickNext={this.gotoNext}
              currentImage={this.state.currentImage}
              isOpen={this.state.lightboxIsOpen}
            />
          </div>
        </div>
      </Fade>
    );
  }
}
export default withStyles(styles)(PhotoGallery);
