import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import MenuList from "@material-ui/core/MenuList";
import classNames from "classnames";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    alignItems: "center",
    padding: "0 15px"
  },
  toolbar: {
    width: "100%",
    maxWidth: "1028px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #e5e5e5"
  },
  navButton: {
    marginLeft: "10px",
    color: "rgba(0, 0, 0, 0.54)",
    height: "100%",
    borderRadius: 0
  },
  navButtonActive: {
    // color: "#ff55aa",
    background: "rgba(0, 0, 0, 0.15)"
  },
  navButtonSideDrawer: {
    width: "200px",
    display: "flex",
    justifyContent: "flex-start",
    padding: "17px"
  },
  logo: {
    height: "110px",
    margin: "10px 0"
  },
  title: {
    "-webkit-tap-highlight-color": "transparent",
    color: "#000",
    display: "inline",
    height: "auto",
    textTransform: "uppercase",
    fontWeight: "200",
    letterSpacing: "5px",
    lineHeight: "18px",
    fontSize: "18px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "24px",
      lineHeight: "24px"
    }
  },
  logoHomeLink: {
    textDecoration: "none",
    cursor: "pointer"
  },
  menuButtons: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  menuDrawerButton: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  avatar: {
    width: "48px",
    height: "48px",
    margin: "10px"
  }
});

class Header extends Component {
  state = {
    rightDrawerOpen: false,
    activeTab: 0
  };

  toggleRightDrawer = () => {
    this.setState({
      rightDrawerOpen: !this.state.rightDrawerOpen
    });
  };

  closeRightDrawer = () => {
    this.setState({ rightDrawerOpen: false });
  };

  handleTabChange = (e, value) => {
    this.setState({
      activeTab: value
    });
  };

  render() {
    const { classes, active } = this.props;
    const { rightDrawerOpen, activeTab } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <a className={classes.logoHomeLink} href="/">
              <h1 className={classes.title}>Paper to Party</h1>
              {/* <img className={classes.logo} 
              src="/images/Logo.png" /> */}
              {/* lavanderia is the logo font */}
            </a>

            <div className={classes.menuButtons}>
              <Button
                className={classNames(classes.navButton, {
                  [classes.navButtonActive]: active === "photos"
                })}
                aria-label="Photos"
                href="/photos"
              >
                Photos
              </Button>
              <Button
                className={classNames(classes.navButton, {
                  [classes.navButtonActive]: active === "about"
                })}
                aria-label="About"
                href="/about"
              >
                About
              </Button>
              <Button
                className={classNames(classes.navButton, {
                  [classes.navButtonActive]: active === "contact"
                })}
                aria-label="Contact"
                href="/contact"
              >
                Contact
              </Button>
            </div>

            <IconButton
              onClick={this.toggleRightDrawer}
              className={classes.menuDrawerButton}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="right"
          open={rightDrawerOpen}
          onClose={this.closeRightDrawer}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.closeRightDrawer}
            onKeyDown={this.closeRightDrawer}
          >
            <MenuList>
              <Button
                className={classes.navButtonSideDrawer}
                aria-label="Photos"
                href="/photos"
              >
                Photos
              </Button>
              <Button
                className={classes.navButtonSideDrawer}
                aria-label="About"
                href="/about"
              >
                About
              </Button>
              <Button
                className={classes.navButtonSideDrawer}
                aria-label="Contact"
                href="/contact"
              >
                Contact
              </Button>
            </MenuList>
          </div>
        </Drawer>
      </div>
    );
  }

  static propTypes = {
    classes: PropTypes.object,
    active: PropTypes.string
  };
}

export default withStyles(styles)(Header);
