import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import registerServiceWorker from './registerServiceWorker';

render((
  <BrowserRouter>
    <div className="app-root">
      <App />
    </div>
  </BrowserRouter>
), document.getElementById('root'));

registerServiceWorker();
