import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import PhotoGallery from "./pages/PhotoGallery";
import NotFound from "./pages/NotFound";
import "./App.css";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff",
      light: "#ffffff",
      dark: "#cccccc",
      contrastText: "#000"
    },
    secondary: {
      main: "#ff55aa",
      light: "#ff8bdc",
      dark: "#c80e7b",
      contrastText: "#fff"
    },
    error: {
      main: "#E57373"
    }
  }
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/photos" component={PhotoGallery} />
          <Route component={NotFound} />
        </Switch>
      </MuiThemeProvider>
    );
  }
}

export default App;
