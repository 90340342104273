import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Header from "./Header";
import Fade from "@material-ui/core/Fade";
import Avatar from "@material-ui/core/Avatar";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  content: {
    padding: "20px 30px",
    maxWidth: "1028px",
    margin: "0 auto"
  },
  avatar: {
    height: "180px",
    width: "180px",
    margin: "20px",
    border: "4px solid rgb(255, 95, 175, 0.2)"
  },
  leader: {
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "13px",
    paddingBottom: "5px"
  },
  mainRow: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row"
    }
  },
  section: {
    marginBottom: "15px"
  },
  link: {
    color: "#4b9bbf",
    lineHeight: "1.5"
  }
});

class Contact extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Fade in={true} timeout={1000}>
        <div className={classes.root}>
          <Header active="contact" />

          <div className={classes.content}>
            <h1>Contact Us</h1>

            <p>
              Paper to Party is located in the greater Chicago Metropolitan
              Area.
            </p>

            <div className={classes.mainRow}>
              <div>
                <Avatar
                  alt="Chicagoland Greater Metropolitan Area"
                  src="/images/chicago-map.png"
                  className={classes.avatar}
                />
              </div>
              <div>
                <div className={classes.section}>
                  <div className={classes.leader}>Phone:</div>
                  <a className={classes.link} href="tel:847-903-2148">
                    847-903-2148
                  </a>
                </div>
                <div className={classes.section}>
                  <div className={classes.leader}>Email:</div>
                  <a
                    className={classes.link}
                    href="mailto:papertoparty@comcast.net"
                  >
                    papertoparty@comcast.net
                  </a>
                </div>
                <div>
                  <div className={classes.leader}>Address:</div>
                  <a
                    className={classes.link}
                    href="https://goo.gl/maps/cv32TPbHuku"
                    target="_blank"
                  >
                    1468 Avignon Court
                    <br />
                    Highland Park, Illinois 60035
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }

  static propTypes = {
    classes: PropTypes.object
  };
}

export default withStyles(styles)(Contact);
