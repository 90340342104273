import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Header from "./Header";
import Avatar from "@material-ui/core/Avatar";
import Fade from "@material-ui/core/Fade";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  content: {
    padding: "20px 30px",
    maxWidth: "1028px",
    margin: "0 auto"
  },
  avatar: {
    width: "200px",
    height: "200px",
    margin: "20px"
  },
  section: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between"
    },
    alignItems: "flex-start",
    marginBottom: "10px"
  }
});

class About extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Fade in={true} timeout={1000}>
        <div className={classes.root}>
          <Header active="about" />

          <div className={classes.content}>
            <h1>About Us</h1>
            <div>
              <div className={classes.section}>
                <div>
                  <p>
                    <b>Paper to Party</b> is a boutique style party planning and
                    printing company. We specialize in weddings, rehearsals,
                    baby and bridal showers, birthdays, Mitzvahs, custom
                    printing, stationery, save-the-dates, invitations, and
                    accessories. We work directly with our clients from start to
                    finish making sure every event we handle is a success. We
                    can coordinate every personal detail including budgets,
                    invitations, decorations, and day-of event management.
                  </p>
                  <p>
                    No matter what the occassion we have you covered. We have
                    over 20 years experience in the party planning and printing
                    industries. Call us today and make your event a smashing
                    success.
                  </p>
                  {/* <p>
                    Paper to Party is a proud member of the Chicagoland Event
                    Pros.
                  </p> */}
                </div>
              </div>
              <div className={classes.section}>
                <Avatar
                  alt="Linda Crown"
                  src="/images/linda-head-shot.jpg"
                  className={classes.avatar}
                />
                <div>
                  <p>
                    <b>Linda Crown</b> is the former owner of F.U.N. Cards &amp;
                    Gifts and is the Chief of Staff at Paper to Party. Linda’s
                    unique sense of style, outgoing personality, and calm
                    demeanor make the planning process a breeze for her clients.
                  </p>

                  <p>
                    Linda has been serving the North Shore for over 20 years. A
                    proud wife and mother of two, Linda treats every party as if
                    it were her own. Linda's amazing ability to turn her
                    client's wildest dreams and fantasies into reality is what
                    separates her from the rest.
                  </p>

                  <p>
                    Be a guest at your own event. Call Linda today to get
                    started.
                  </p>

                  <p>References available upon request.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }

  static propTypes = {
    classes: PropTypes.object
  };
}

export default withStyles(styles)(About);
