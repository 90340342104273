import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Header from "./Header";
import Fade from "@material-ui/core/Fade";

const styles = theme => ({
  root: {
    flexGrow: 1,
    background: "url(/images/gallery/gallery-02.jpg)",
    height: "100%",
    minHeight: "100vh",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    backgroundSize: "cover",
    flexGrow: 1
  },
  rootInner: {
    background: "rgba(0, 0, 0, 0.5)",
    height: "100%",
    width: "100%"
  },
  content: {
    padding: "20px 30px",
    color: "#ffffff",
    maxWidth: "1028px",
    margin: "0 auto",
    textAlign: "center"
  },
  pageTitle: {
    fontSize: "1.9em",
    marginTop: 0
  },
  sectionRow: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row"
    },
    justifyContent: "center",
    paddingBottom: "30px"
  },
  list: {
    listStyle: "none"
  }
});

class Home extends Component {
  // TODO: alter the site to use this template here as motivation for the home page and some call outs to the different galleries
  // https://template65.carrd.co
  render() {
    const { classes } = this.props;

    return (
      <Fade in={true} timeout={1000}>
        <div className={classes.root}>
          <div className={classes.rootInner}>
            <Header active="home" />

            <div className={classes.content}>
              <h1 className={classes.pageTitle}>
                Your Party and Event Planning Headquarters
              </h1>
              <h2>Providing Quality Service for over 20 years</h2>
              <h2>No Party is too Big or Small</h2>
              <div className={classes.sectionRow}>
                <div>
                  <h3>Event Planning</h3>
                  <ul className={classes.list}>
                    <li>Weddings</li>
                    <li>Corporate Events</li>
                    <li>Bar and Bat Mitzvahs</li>
                    <li>Day of Events</li>
                  </ul>
                </div>
              </div>{" "}
              <div className={classes.sectionRow}>
                <div>
                  <h3>Custom Printing</h3>
                  <ul className={classes.list}>
                    <li>Invitations</li>
                    <li>Stationery</li>
                    <li>Placecards</li>
                    <li>Save the Date</li>
                    <li>Sign-in Books</li>
                    <li>Napkins</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }

  static propTypes = {
    classes: PropTypes.object
  };
}

export default withStyles(styles)(Home);
