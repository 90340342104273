import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Header from "./Header";
import Fade from "@material-ui/core/Fade";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  content: {
    padding: "20px 30px",
    maxWidth: "1028px",
    margin: "0 auto"
  }
});

class Contact extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Fade in={true} timeout={1000}>
        <div className={classes.root}>
          <Header active={null} />

          <div className={classes.content}>
            <h1>Not Found</h1>
          </div>
        </div>
      </Fade>
    );
  }

  static propTypes = {
    classes: PropTypes.object
  };
}

export default withStyles(styles)(Contact);
